.error-message {
  padding-top: 40px;
  text-align: center;
  font-size: 36px !important;
  line-height: 42px;
  color: $blue;
  font-weight: 500;

  .error-text {
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: black;
    button {
      margin: auto;
    }
  }
}

.run-vin-page {
  overflow: auto;

  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  max-width: 1280px;
  height: 100vh;

  header {
    z-index: 100;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.22);

    .top-bar {
      display: flex;
      justify-content: space-between;

      .logos {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 16px;
        @include for-phone-only {
          margin: 12px;
        }

        .divider {
          width: 1px;
          height: 32px;
          margin: 0px 14px 0px 14px;
          border-left: 1px gray solid;
          @include for-phone-only {
            width: 1px;
            height: 25px;
            margin: 0px 0px 0px 7px;
            border-left: 1px gray solid;
            // visibility: hidden;
            // display: none;
          }
        }
        .carfax-logo {
          width: 166px;
          height: 32px;
          @include for-phone-only {
            width: 140px;
            height: 24px;
            // visibility: hidden;
            // display: none;
          }
        }
        .ihs-logo {
          @include for-phone-only {
            height: 51px;
            margin-left: 0;
            // visibility: hidden;
            // display: none;
          }

          height: 62px;
          margin-top: -15px;
          margin-left: -15px;
        }
      }
      .top-bar--right-section {
        display: flex;
        align-items: center;
        font-weight: 500;

        p {
          font-family: "Roboto", Helvetica, Arial, sans-serif;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          color: $black;
          margin-right: 24px;

          @include for-phone-only {
            visibility: hidden;
            display: none;
            line-height: 20px;
            font-size: 11px;
            margin: 10px 0px 0px 10px;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        button {
          font-family: "Roboto", Helvetica, Arial, sans-serif;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          color: $black;
          margin-right: 24px;
          @include for-phone-only {
            margin-right: 12px;
          }
        }
      }
    }

    .run-vin-header-image {
      // background-image: url('../../assets/images/run-vin-header.png');
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: bottom;
      // background-position-y: 66px;
      height: 444px;
      width: 100%;
    }
  }

  main {
    @media (max-height: 655px) {
      padding-bottom: 128px;
    }
    text-align: center;
    padding-bottom: 192px;

    h1 {
      @include for-phone-only {
        font-size: 30px;
        line-height: 45px;
      }
      @include for-tablet-portrait-up {
        font-size: 35px;
        line-height: 50px;
      }
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      color: #1976d2;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 48px;
      text-align: center;
      margin-top: 56px;
    }

    h2 {
      @include for-phone-only {
        font-size: 20px;
      }
      @include for-tablet-portrait-up {
        font-size: 24px;
      }
      color: #212121;
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }

    .vin-box-wrapper {
      @include for-phone-only {
        justify-content: center;
        margin: 24px 10px 24px 10px;
      }
      @include for-tablet-portrait-up {
        width: 484px;
      }
      display: flex;
      margin: auto;
      margin-top: 24px;

      .vin-text-input-wrapper {
        @include for-phone-only {
          width: auto;
        }
        @include for-tablet-portrait-up {
          width: 359px;
        }
        height: 48px;

        input {
          border-radius: 4px 0px 0px 4px;
          font-size: 16px !important;
          height: 48px;
        }
      }
      .run-vin-button {
        height: 48px;
        width: 135px;
        border-radius: 0px 4px 4px 0px;
      }
    }

    .run-vin-error {
      @include for-tablet-portrait-up {
        width: 484px;
      }
      margin: auto;
      margin-top: 24px;

      a {
        color: red;
        font-weight: 500;
      }
    }
  }

  footer {
    @media (max-height: 655px) {
      display: none;
    }

    z-index: 10;
    bottom: 0;
    left: 0px;
    height: 100px;
    border-top: 1px solid #bdbdbd;
    background-color: white;
    width: 100%;
    padding-bottom: 12px;
    position: absolute;

    @include for-phone-only {
      visibility: hidden;
    }

    > div {
      margin: auto;
      display: flex;
      justify-content: space-between;

      .footer-left-content {
        img {
          height: 26.61px;
          width: 138.91px;
          margin: 24px 0px 0px 24px;
          @include for-phone-only {
            width: 130px;
            height: 23px;
            margin: 10px 0px 0px 10px;
          }
        }

        p {
          margin: 10px 0px 0px 24px;
          color: #424242;
          font-size: 12px;
          line-height: 24px;
          @include for-phone-only {
            line-height: 20px;
            font-size: 11px;
            margin: 10px 0px 0px 10px;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      .footer-right-content {
        img {
          float: right;
          height: 34px;
          width: 138.91px;
          margin: 18px 13px 0px 24px;
          @include for-phone-only {
            width: 130px;
            height: 23px;
            margin: 10px 0px 0px 10px;
          }
        }

        div {
          clear: both;
          margin-right: 24px;
          padding-top: 61px;
          color: #424242;
          font-size: 12px;
          line-height: 24px;
          @include for-phone-only {
            visibility: hidden;
          }
        }
      }
    }
  }
}
