body {
  background-color: $grey-lightest;
}

.login-page {
  min-height: 100%;
  header {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    @include boxShadow(0.25);
    display: flex;
    flex-direction: row;

    .divider {
      width: 1px;
      height: 32px;
      margin: 0px 14px 0px 14px;
      border-left: 1px lightgray solid;
    }
    .carfax-logo {
      width: 166px;
      height: 32px;
    }
    .ihs-logo {
      height: 62px;
      margin-left: -12px;
    }
    // img {
    //   width: 159.2px;
    //   height: 30.86px;
    // }
  }
  main {
    .error-msg {
      text-align: center;
      color: red;
      padding: 0.5em 0.25em;
    }
    .login-box {
      height: 800px;

      @include for-phone-only {
        width: calc(100% - 48px);
        margin: 24px;
      }

      @include for-tablet-portrait-up {
        width: 527px;
        max-width: 100%;
        margin: 88px auto;
        border-radius: 4px;
        height: 758px;
      }

      height: 730px;
      box-shadow: 0 15px 12px 0 rgba(0, 0, 0, 0.22), 0 19px 38px 0 rgba(0, 0, 0, 0.3);
      background-color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 64px;

      img {
        margin-top: 48px;
        margin-bottom: 48px;
        width: 294px;
        @media only screen and (max-width: 500px) {
          width: 250px;
        }
      }
      h3 {
        color: $grey-darkest;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        margin-bottom: 5px;
      }
      .header-text {
        margin-top: 64px;
        margin-bottom: 8px;
        color: #212121;
        font-family: "Roboto", Helvetica, Arial, sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
      }
      .sub-header-text {
        line-height: 24px;
        color: #616161;
        font-family: "Roboto", Helvetica, Arial, sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        max-width: 400px;
      }

      @include button-stencils-dark-theme-override-green();

      button {
        height: 48px;
        width: 399px;
        margin-bottom: 10px;
        @media only screen and (max-width: 500px) {
          width: 250px;
        }
      }
      .login-btn-spacer {
        padding-bottom: 18px;
        font-size: 20px;
        text-align: center;
      }
    }
  }

  .markit-logo {
    float: right;
    clear: both;
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 140px;
    height: 40px;
    @include for-phone-only {
      visibility: hidden;
    }
  }
}
