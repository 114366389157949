@import "~@carfax-stencils/styles/src/index.scss";
@import "~@carfax-stencils/button/src/index.scss";
@import "~@carfax-stencils/input-text/src/index.scss";
@import "~@carfax-stencils/spinner/src/index.scss";

#app {
  min-height: 100%;
  > div {
    min-height: 100%;
    #browser-router {
      min-height: 100%;
    }
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin button-stencils-dark-theme-override-green {
  // override stencils default style
  button[data-theme="dark"] {
    background-color: #005005 !important;
  }
  button[data-theme="dark"]:hover {
    background-color: rgba(0, 80, 5, 0.8) !important;
  }
  button[data-theme="dark"]:disabled {
    background-color: rgba(0, 80, 5, 0.8) !important;
  }
}

//LAYOUT

//COMMON

//PAGES
@import "components/pages/loginPage";
@import "components/pages/runVinPage";

//FORMS

//CONTENT

//MODALS
